import React, {useEffect, useState} from "react";
import {getNewRegistrationReport} from "../serivces/reportService";
import LoadingSpinner from "../../../component/LoadingSpinner";
import CustomerLoyaltyLineChart from "../charts/CustomerLoyaltyLineChart";
import {Serie} from "@nivo/line";
import DateRangeInput from "../components/DateRangeInput";

const maxChartWidth = 1000;

const CustomerNewRegistrationReports: React.FC = () => {
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const defaultFromYear = oneMonthAgo.getFullYear().toString();
  const defaultFromMonth = (oneMonthAgo.getMonth() + 1).toString();
  const defaultFromDay = oneMonthAgo.getDate().toString();
  const defaultToYear = today.getFullYear().toString();
  const defaultToMonth = (today.getMonth() + 1).toString();
  const defaultToDay = today.getDate().toString();

  const [fromYear, setFromYear] = useState<string>(defaultFromYear);
  const [fromMonth, setFromMonth] = useState<string>(defaultFromMonth);
  const [fromDay, setFromDay] = useState(defaultFromDay);
  const [toYear, setToYear] = useState<string>(defaultToYear);
  const [toMonth, setToMonth] = useState<string>(defaultToMonth);
  const [toDay, setToDay] = useState(defaultToDay);

  const [reportData, setReportData] = useState<Serie[]>();
  const [chartWidth, setChartWidth] = useState<number>(800);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);


  useEffect(() => {
    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  useEffect(() => {
    handleApplyFilter();
    // eslint-disable-next-line
  }, []);

  const handleApplyFilter = async () => {
    setError(undefined);

    const fromDate = new Date(parseInt(fromYear, 10), parseInt(fromMonth, 10) - 1);
    const toDate = new Date(parseInt(toYear, 10), parseInt(toMonth, 10) - 1);

    if (fromDate > toDate) {
      setError("'From' date cannot be later than 'To' date.");
      return;
    }

    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      const reportData = await getReportData();

      setReportData(reportData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateChartWidth = () => {
    setChartWidth(window.innerWidth * 0.8 < maxChartWidth ? window.innerWidth * 0.8 : maxChartWidth);
  };

  const getReportData = async () => {
    const rows = await getNewRegistrationReport({
      fromYear: fromYear.padStart(4, '0'),
      fromMonth: fromMonth.padStart(2, '0'),
      fromDay: fromDay.padStart(2, '0'),
      toYear: toYear.padStart(4, '0'),
      toMonth: toMonth.padStart(2, '0'),
      toDay: toDay.padStart(2, '0'),
    });

    const startDate = new Date(`${fromYear}-${fromMonth}-${fromDay}`);
    const endDate = new Date(`${toYear}-${toMonth}-${toDay}`);
    const uniqueDays: string[] = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      uniqueDays.push(d.toISOString().split('T')[0]);
    }

    const groupedData: Record<string, Record<string, number>> = rows.reduce((acc, row) => {
      const xValue = `${row.register_date}`;

      if (!acc[row.store]) {
        acc[row.store] = {};
      }

      acc[row.store][xValue] = Number(row.count);

      return acc;
    }, {} as Record<string, Record<string, number>>);

    return Object.keys(groupedData).map(key => ({
      id: key,
      data: uniqueDays.map(date => ({
        x: date,
        y: groupedData[key][date] || 0,
      })),
    }));
  }

  return (
    <>
      <DateRangeInput
        defaultFromYear={defaultFromYear}
        defaultFromMonth={defaultFromMonth}
        defaultFromDay={defaultFromDay}
        defaultToYear={defaultToYear}
        defaultToMonth={defaultToMonth}
        defaultToDay={defaultToDay}
        fromYear={fromYear}
        fromMonth={fromMonth}
        fromDay={fromDay}
        toYear={toYear}
        toMonth={toMonth}
        toDay={toDay}
        setFromYear={setFromYear}
        setFromMonth={setFromMonth}
        setFromDay={setFromDay}
        setToYear={setToYear}
        setToMonth={setToMonth}
        setToDay={setToDay}
        handleApplyFilter={handleApplyFilter}
      />

      {error && <div className="text-sm text-red-500">{error}</div>}

      <div className="flex flex-col items-center justify-center space-y-8 mb-16 mt-8">
        {isLoading && (
          <div className="mt-16">
              <LoadingSpinner/>
          </div>
        )}

        {!isLoading && reportData && reportData.length > 0 && (
          <>
            <div style={{width: chartWidth, height: chartWidth / 1.5}}>
              <CustomerLoyaltyLineChart data={reportData} tickRotation={90}/>
            </div>
          </>
        )}
        {!isLoading && reportData && reportData.length === 0 && (
          <div className="text-lg text-gray-500">No data available for the selected date range.</div>
        )}
      </div>
    </>
  );
};

export default CustomerNewRegistrationReports;
