import React, {useEffect, useState} from "react";
import {getLoyaltyTierReport} from "../serivces/reportService";
import LoadingSpinner from "../../../component/LoadingSpinner";
import CustomerLoyaltyLineChart from "../charts/CustomerLoyaltyLineChart";
import {Serie} from "@nivo/line";
import DateRangeInput from "../components/DateRangeInput";
import {LoyaltyTierEventTypeEnum} from "../enum/LoyaltyTierEventTypeEnum";

const maxChartWidth = 1000;

const CustomerLoyaltyTierReports: React.FC = () => {
  const today = new Date();
  const sixMonthsAgo = new Date(today);
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  const defaultFromYear = sixMonthsAgo.getFullYear().toString();
  const defaultFromMonth = (sixMonthsAgo.getMonth() + 1).toString();
  const defaultToYear = today.getFullYear().toString();
  const defaultToMonth = (today.getMonth() + 1).toString();

  const [fromYear, setFromYear] = useState<string>(defaultFromYear);
  const [fromMonth, setFromMonth] = useState<string>(defaultFromMonth);
  const [toYear, setToYear] = useState<string>(defaultToYear);
  const [toMonth, setToMonth] = useState<string>(defaultToMonth);

  const [upgradeReportData, setUpgradeReportData] = useState<Serie[]>();
  const [downgradeReportData, setDowngradeReportData] = useState<Serie[]>()
  const [chartWidth, setChartWidth] = useState<number>(800);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);


  useEffect(() => {
    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  useEffect(() => {
    handleApplyFilter();
    // eslint-disable-next-line
  }, []);

  const handleApplyFilter = async () => {
    setError(undefined);

    const fromDate = new Date(parseInt(fromYear, 10), parseInt(fromMonth, 10) - 1);
    const toDate = new Date(parseInt(toYear, 10), parseInt(toMonth, 10) - 1);

    if (fromDate > toDate) {
      setError("'From' date cannot be later than 'To' date.");
      return;
    }

    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      const [upgradeData, downgradeData] = await Promise.all([
        getReportData(LoyaltyTierEventTypeEnum.UPGRADE),
        getReportData(LoyaltyTierEventTypeEnum.DOWNGRADE)
      ]);

      setUpgradeReportData(upgradeData);
      setDowngradeReportData(downgradeData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateChartWidth = () => {
    setChartWidth(window.innerWidth * 0.8 < maxChartWidth ? window.innerWidth * 0.8 : maxChartWidth);
  };

  const getReportData = async (type: LoyaltyTierEventTypeEnum) => {
    const rows = await getLoyaltyTierReport({
      fromYear: fromYear.padStart(4, '0'),
      fromMonth: fromMonth.padStart(2, '0'),
      toYear: toYear.padStart(4, '0'),
      toMonth: toMonth.padStart(2, '0'),
      type: type,
    });

    const startYear = parseInt(fromYear);
    const startMonth = parseInt(fromMonth);
    const endYear = parseInt(toYear);
    const endMonth = parseInt(toMonth);

    const uniqueMonths: string[] = [];

    for (let year = startYear; year <= endYear; year++) {
      const startM = year === startYear ? startMonth : 1;
      const endM = year === endYear ? endMonth : 12;

      for (let month = startM; month <= endM; month++) {
        uniqueMonths.push(`${year}-${month.toString().padStart(2, '0')}`);
      }
    }

    const groupedData: Record<string, Record<string, number>> = rows.reduce((acc, row) => {
      const { name, year, month, count } = row;
      const xValue = `${year}-${month.toString().padStart(2, '0')}`;

      if (!acc[name]) {
        acc[name] = {};
      }

      acc[name][xValue] = Number(count);

      return acc;
    }, {} as Record<string, Record<string, number>>);

    return Object.keys(groupedData).map(key => ({
      id: key,
      data: uniqueMonths.map(month => ({
        x: month,
        y: groupedData[key][month] || 0,
      })),
    }));
  }

  return (
    <>
      <DateRangeInput
        defaultFromYear={defaultFromYear}
        defaultFromMonth={defaultFromMonth}
        defaultToYear={defaultToYear}
        defaultToMonth={defaultToMonth}
        fromYear={fromYear}
        fromMonth={fromMonth}
        toYear={toYear}
        toMonth={toMonth}
        setFromYear={setFromYear}
        setFromMonth={setFromMonth}
        setToYear={setToYear}
        setToMonth={setToMonth}
        handleApplyFilter={handleApplyFilter}
      />

      {error && <div className="text-sm text-red-500">{error}</div>}

      <div className="flex flex-col items-center justify-center space-y-8 mb-16 mt-8">
        {isLoading && (
          <div className="mt-16">
              <LoadingSpinner/>
          </div>
        )}

        {!isLoading && upgradeReportData && upgradeReportData.length > 0 && (
          <>
            <h3 className="text-lg font-semibold mt-4">Upgrade</h3>
            <div style={{width: chartWidth, height: chartWidth / 1.5}}>
              <CustomerLoyaltyLineChart data={upgradeReportData}/>
            </div>
          </>
        )}

        {!isLoading && downgradeReportData && downgradeReportData.length > 0 && (
          <>
            <h3 className="text-lg font-semibold mt-4">Downgrade</h3>
            <div style={{width: chartWidth, height: chartWidth / 1.5}}>
              <CustomerLoyaltyLineChart data={downgradeReportData}/>
            </div>
          </>
        )}

        {!isLoading && upgradeReportData && downgradeReportData && upgradeReportData.length === 0 && downgradeReportData.length === 0 && (
          <div className="text-lg text-gray-500">No data available for the selected date range.</div>
        )}
      </div>
    </>
  );
};

export default CustomerLoyaltyTierReports;
