import React, { useState } from "react";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import CustomerLoyaltyTierReports from "./CustomerLoyaltyTierReports";
import CustomerNewRegistrationReports from "./CustomerNewRegistrationReports";

// Define the report types and their corresponding components
const reportTypes = [
  { label: "Customer Loyalty Tier", value: "CLT", component: CustomerLoyaltyTierReports },
  { label: "Customer New Registration", value: "CNR", component: CustomerNewRegistrationReports },
];

const Report: React.FC = () => {
  const [reportType, setReportType] = useState<string | undefined>();

  const selectedReport = reportTypes.find((report) => report.value === reportType);

  return (
    <div className="flex">
      <SideMenu />
      <div className="flex-1">
        <Header title="Reports" />
        <div className="p-4 ml-8">
          <div className="mt-4 mb-8">
            <label htmlFor="reportType" className="block text-sm font-medium text-gray-900">
              Report Type
            </label>
            <select
              id="reportType"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              className="p-1 mt-1 w-1/2 min-w-60 max-w-[30rem] rounded-md border border-gray-200 shadow-sm sm:text-md"
            >
              <option value="">Select a report type</option>
              {reportTypes.map((report) => (
                <option key={report.value} value={report.value}>
                  {report.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-8">
            {selectedReport?.component && <selectedReport.component />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
