import React from 'react';
import logo from "../assets/logo.png";
import {LuFileLineChart} from "react-icons/lu";
import {useNavigate} from "react-router-dom";

const SideMenu = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen w-16 flex-col bg-gray-300 justify-between border-e">
      <div>
        <div className="inline-flex size-16 items-center justify-center">
          <span className="grid size-10 place-content-center rounded-lg bg-gray-600 text-xs text-gray-600">
            <img src={logo} alt="Logo" className="w-24 h-auto"/>
          </span>
        </div>

        <div
          className="sticky inset-x-0 bottom-0 border-t border-gray-400 p-2"> {/* Updated background color */}
          <button
            onClick={() => navigate('/report')}
            className="group relative flex w-full justify-center rounded-lg px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
          >
            <LuFileLineChart className="w-6 h-6"/>
            <span
              className="invisible absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white group-hover:visible">
              Reports
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
