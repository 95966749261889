type RequestOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  headers?: Record<string, string>;
  body?: any;
};

interface ApiError extends Error {
  status?: number;
  statusText?: string;
  responseBody?: any;
}

export const getApi = async (url: string, query?: any, headers?: Record<string, string>) => {
  const queryString = query ? `?${new URLSearchParams(query).toString()}` : '';
  return fetchApi({
    method: 'GET',
    url: `${url}${queryString}`,
    headers,
  });
}

export const postApi = async (url: string, body?: any, headers?: Record<string, string>) => {
  return fetchApi({
    method: 'POST',
    url,
    headers,
    body,
  });
}

export const putApi = async (url: string, id: string, body?: any, headers?: Record<string, string>) => {
  url = `${url}/${id}`;
  return fetchApi({
    method: 'PUT',
    url,
    headers,
    body,
  });
}

export const deleteApi = async (url: string, id: string, headers?: Record<string, string>) => {
  url = `${url}/${id}`;
  return fetchApi({
    method: 'DELETE',
    url,
    headers,
  });
}

const fetchApi = async <T>({ method, url, headers = {}, body }: RequestOptions): Promise<T> => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
      if (response.status === 401) {
        await handleTokenExpired();
      }

      const errorBody = await response.json().catch(() => ({})); // Handle cases where the body is not JSON
      const error: ApiError = new Error(`Request failed with status ${response.status} (${response.statusText})`);
      error.status = response.status;
      error.statusText = response.statusText;
      error.responseBody = errorBody;
      throw error;
    }

    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(`Unknown error occurred in ${method} ${url}`);
    }
  }
};

export const handleTokenExpired = async () => {
  alert("Session expired. Please login again.");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.reload();
}

export const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    // Prod api server is hosted with path /api, e.g. https://example.com/api. Setting a port will have problems, so use default port 80.
    case "prod":
      return `${process.env.REACT_APP_API_HOST}`;
    case "dev":
      return `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api`;
    default:
      throw new Error("Unknown environment. Please set REACT_APP_ENV to either 'prod' or 'dev'");
  }
}
