import React from 'react';

interface DateRangeInputProps {
  defaultFromYear: string;
  defaultFromMonth: string;
  defaultToYear: string;
  defaultToMonth: string;
  fromYear: string;
  fromMonth: string;
  toYear: string;
  toMonth: string;
  setFromYear: React.Dispatch<React.SetStateAction<string>>;
  setFromMonth: React.Dispatch<React.SetStateAction<string>>;
  setToYear: React.Dispatch<React.SetStateAction<string>>;
  setToMonth: React.Dispatch<React.SetStateAction<string>>;
  handleApplyFilter: () => void;

  defaultFromDay?: string;
  defaultToDay?: string;
  fromDay?: string;
  toDay?: string;
  setFromDay?: React.Dispatch<React.SetStateAction<string>>;
  setToDay?: React.Dispatch<React.SetStateAction<string>>;
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  defaultFromYear,
  defaultFromMonth,
  defaultToYear,
  defaultToMonth,
  fromYear,
  fromMonth,
  toYear,
  toMonth,
  setFromYear,
  setFromMonth,
  setToYear,
  setToMonth,
  handleApplyFilter,

  defaultFromDay = "1",
  defaultToDay = "1",
  fromDay = null,
  toDay = null,
  setFromDay = null,
  setToDay = null,
}) => {
  const handleYearInput = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const value = e.target.value;
    const numericValue = parseInt(value, 10);

    setter(value);

    if (e.type === 'blur' && (!value || numericValue < 1900 || numericValue > 2100)) {
      if (setter === setFromYear) {
        setter(defaultFromYear);
      } else {
        setter(defaultToYear);
      }
    }
  };

  const handleMonthInput = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const value = e.target.value;
    const numericValue = parseInt(value, 10);

    setter(value);

    if (e.type === 'blur' && (!value || numericValue < 1 || numericValue > 12)) {
      if (setter === setFromMonth) {
        setter(defaultFromMonth);
      } else {
        setter(defaultToMonth);
      }
    }
  };

  const handleDayInput = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>, month: string, year: string) => {
    const value = e.target.value;
    const numericValue = parseInt(value, 10);

    setter(value);

    if (e.type === 'blur' && (!value || numericValue < 1 || numericValue > getDaysInMonth(parseInt(month), parseInt(year)))) {
      if (setter === setFromDay) {
        setter(defaultFromDay);
      } else {
        setter(defaultToDay);
      }
    }
  };

  const getDaysInMonth = (month: number, year: number): number => {
    return new Date(year, month, 0).getDate();
  };


  return (
    <div className="flex flex-wrap mt-4 mb-2">
      <div className="flex flex-col w-24 aspect-w-1 aspect-h-1 mr-4 mb-4">
        <label htmlFor="fromYear" className="block text-sm font-medium text-gray-700">From Year</label>
        <input
          id="fromYear"
          type="number"
          value={fromYear}
          onChange={(e) => handleYearInput(e, setFromYear)}
          onBlur={(e) => handleYearInput(e, setFromYear)}
          className="p-1 mt-1 w-full rounded-md border border-gray-200 shadow-sm sm:text-md"
        />
      </div>
      <div className="flex flex-col w-24 aspect-w-1 aspect-h-1 mr-4 mb-4">
        <label htmlFor="fromMonth" className="block text-sm font-medium text-gray-700">From Month</label>
        <input
          id="fromMonth"
          type="number"
          value={fromMonth}
          onChange={(e) => handleMonthInput(e, setFromMonth)}
          onBlur={(e) => handleMonthInput(e, setFromMonth)}
          className="p-1 mt-1 w-full rounded-md border border-gray-200 shadow-sm sm:text-md"
        />
      </div>
      {fromDay != null && setFromDay && handleDayInput && (
        <div className="flex flex-col w-24 aspect-w-1 aspect-h-1 mr-4 mb-4">
          <label htmlFor="fromDay" className="block text-sm font-medium text-gray-700">From Day</label>
          <input
            id="fromDay"
            type="number"
            value={fromDay}
            onChange={(e) => handleDayInput(e, setFromDay, fromMonth, fromYear)}
            onBlur={(e) => handleDayInput(e, setFromDay, fromMonth, fromYear)}
            className="p-1 mt-1 w-full rounded-md border border-gray-200 shadow-sm sm:text-md"
          />
        </div>
      )}
      <div className="flex flex-col w-24 aspect-w-1 aspect-h-1 mr-4 mb-4">
        <label htmlFor="toYear" className="block text-sm font-medium text-gray-700">To Year</label>
        <input
          id="toYear"
          type="number"
          value={toYear}
          onChange={(e) => handleYearInput(e, setToYear)}
          onBlur={(e) => handleYearInput(e, setToYear)}
          className="p-1 mt-1 w-full rounded-md border border-gray-200 shadow-sm sm:text-md"
        />
      </div>
      <div className="flex flex-col w-24 aspect-w-1 aspect-h-1 mr-4 mb-4">
        <label htmlFor="toMonth" className="block text-sm font-medium text-gray-700">To Month</label>
        <input
          id="toMonth"
          type="number"
          value={toMonth}
          onChange={(e) => handleMonthInput(e, setToMonth)}
          onBlur={(e) => handleMonthInput(e, setToMonth)}
          className="p-1 mt-1 w-full rounded-md border border-gray-200 shadow-sm sm:text-md"
        />
      </div>
      {toDay!= null && setToDay && handleDayInput && (
        <div className="flex flex-col w-24 aspect-w-1 aspect-h-1 mr-4 mb-4">
          <label htmlFor="toDay" className="block text-sm font-medium text-gray-700">To Day</label>
          <input
            id="toDay"
            type="number"
            value={toDay}
            onChange={(e) => handleDayInput(e, setToDay, toMonth, toYear)}
            onBlur={(e) => handleDayInput(e, setToDay, toMonth, toYear)}
            className="p-1 mt-1 w-full rounded-md border border-gray-200 shadow-sm sm:text-md"
          />
        </div>
      )}

      <button
        onClick={handleApplyFilter}
        className="aspect-w-1 aspect-h-1 mt-4 h-10 px-2 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 whitespace-nowrap"
      >
        Apply Filter
      </button>
    </div>
  );
};

export default DateRangeInput;
