import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {loginUser} from "./authService";

interface User {
  email: string;
  role: string;
  token?: string;
}

interface AuthContextType {
  user: User | undefined;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  loading: boolean;
}

const allowedRoles = ["ADMIN", "MANAGER", "EMPLOYEE"];

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      const user = await loginUser(email, password) as User;
      if (!allowedRoles.includes(user.role)) {
        throw new Error("Permission denied");
      }

      if (user.token) {
        localStorage.setItem('token', user.token);
      }

      delete user.token;
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(undefined);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
