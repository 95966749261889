import React from "react";

const NotFound: React.FC = () => {
  return (
    <div className="grid h-screen place-content-center bg-white px-4">
      <div className="text-center">
        <h1 className="text-6xl font-black text-gray-200">404</h1>
        <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Uh-oh!</p>
        <p className="m-4 text-gray-500">We can't find that page.</p>
        <a
          href="/"
          className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go Back Home
        </a>
      </div>
    </div>
  )
}

export default NotFound;
