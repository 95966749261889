import {GetLoyaltyTierReportResDto} from "../dto/GetLoyaltyTierReportResDto";
import {getApi, getBaseUrl} from "../../../services/apiService";
import {GetNewRegistrationReportResDto} from "../dto/GetNewRegistrationReportResDto";
import {GetReportDateTimeDto} from "../dto/GetReportDateTimeReqDto";
import {GetLoyaltyTierReportReqDto} from "../dto/GetLoyaltyTierReportReqDto";

const reportBaseUrl = `${getBaseUrl()}/reports`;

export const getLoyaltyTierReport = async (dto: GetLoyaltyTierReportReqDto): Promise<GetLoyaltyTierReportResDto[]> => {
  const response = await getApi(`${reportBaseUrl}/loyalty`, dto);
  return response as GetLoyaltyTierReportResDto[];
};

export const getNewRegistrationReport = async (dto: GetReportDateTimeDto): Promise<GetNewRegistrationReportResDto[]> => {
  const response = await getApi(`${reportBaseUrl}/new-customers`, dto);
  return response as GetNewRegistrationReportResDto[];
}
