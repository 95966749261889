import React from 'react';
import {ResponsiveLine, Serie} from "@nivo/line";

interface CustomerLoyaltyLineChartProps {
  data: Serie[];
  tickRotation?: number;
}

const CustomerLoyaltyLineChart: React.FC<CustomerLoyaltyLineChartProps> = ({
  data,
  tickRotation = 0
}) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 150, bottom: 100, left: 80 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 0, max: 'auto' }}
      axisBottom={{
        legend: 'Time',
        legendOffset: 80,
        tickRotation: tickRotation,
        legendPosition: 'middle',
      }}
      axisLeft={{
        legend: 'Count',
        legendOffset: -60,
        legendPosition: 'middle',
        format: (e) => (Math.floor(e) === e ? e : ""),
      }}
      colors={{ scheme: 'nivo' }}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  );
};

export default CustomerLoyaltyLineChart;
