import React from 'react';
import spinner from '../assets/loading-spinner.gif';

const LoadingSpinner: React.FC = () => {
  return (
    <img src={spinner} alt="Loading..." className="max-w-28 min-w-24 h-auto"/>
  );
};

export default LoadingSpinner;
