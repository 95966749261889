import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import {useAuth} from "../modules/auth/services/AuthContext";

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <header className="bg-white shadow relative">
      <div className="max-w-10xl mx-auto flex justify-between items-center py-4 px-4 sm:px-1 lg:px-8">
        <h1 className="text-2xl font-bold text-gray-700 ml-4">{title}</h1>
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="text-gray-600 font-medium focus:outline-none mr-2"
          >
            {user?.email}
          </button>

          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-md">
              {/*<ul className="py-2">*/}
              {/*  <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Setting</li>*/}
              {/*</ul>*/}
              {/*<hr className="border-gray-200"/>*/}
              <div className="px-4 py-2">
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Sign out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
